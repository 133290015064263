@font-face {
    font-family: 'Metronic Pro';
    src: url('../fonts/MetronicProRegular.eot');
    src: local('Metronic Pro Regular'), local('MetronicProRegular'),
        url('../fonts/MetronicProRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MetronicProRegular.woff2') format('woff2'),
        url('../fonts/MetronicProRegular.woff') format('woff'),
        url('../fonts/MetronicProRegular.ttf') format('truetype'),
        url('../fonts/MetronicProRegular.svg#MetronicProRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Metronic Pro Light';
    src: url('../fonts/MetronicProLight.eot');
    src: local('Metronic Pro Light'), local('MetronicProLight'),
        url('../fonts/MetronicProLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MetronicProLight.woff2') format('woff2'),
        url('../fonts/MetronicProLight.woff') format('woff'),
        url('../fonts/MetronicProLight.ttf') format('truetype'),
        url('../fonts/MetronicProLight.svg#MetronicProLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Metronic Pro Bold';
    src: url('../fonts/MetronicProBold.eot');
    src: local('Metronic Pro Bold'), local('MetronicProBold'),
        url('../fonts/MetronicProBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MetronicProBold.woff2') format('woff2'),
        url('../fonts/MetronicProBold.woff') format('woff'),
        url('../fonts/MetronicProBold.ttf') format('truetype'),
        url('../fonts/MetronicProBold.svg#MetronicProBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


body {
    font-family: 'Metronic Pro';
    font-size: $body-font;
}

strong{
    font-family: 'Metronic Pro Bold';
    font-weight: bold;
}

p{
    line-height: 1.2;
}
