.main{
    padding-top: rem-calc(120);

    @include breakpoint(small down){
        padding-top: rem-calc(60);
    }

    &__table{
        text-align: center;
        padding: rem-calc(0 15);
        padding-top: rem-calc(160);
        box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.2);
        background: $white;
        position: relative;

        @include breakpoint(medium down){
            margin-bottom: rem-calc(60);
        }

        @include breakpoint(small down){
            padding: 0;
            padding-top: rem-calc(70);
        }

        h5, h4{
            font-size: $body-font;
            font-family: 'Metronic Pro Light';
        }

        h5{
            text-align: right;
            line-height: 1.2;
            margin: rem-calc(10 0);
            margin-right: rem-calc(40);

            @include breakpoint(medium down){
                text-align: center;
                margin-right: rem-calc(20);
            }

            @include breakpoint(small down){
                margin-right: 0;
                margin-left: rem-calc(10);
            }
        }

        .grid-x:first-child{
            position: absolute;
            width: 100%;
            top: rem-calc(-25);
            left: 0;

            @include breakpoint(small down){
                position: relative;
            }
        }

        .grid-x:nth-child(2n + 3){
            background: $gray;
        }
    }

    &__table-label{
        position: relative;

        @include breakpoint(small down){
            width: 80%;
            margin: 0 auto;
            margin-right: rem-calc(11);
        }

        p{
            font-family: 'Metronic Pro Light';
            font-weight: 300;
            font-size: rem-calc(16);
            line-height: 1.6;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $white;

            @include breakpoint(small down){
                line-height: 1.2;
                font-size: rem-calc(14);
            }
        }
    }

    &__table-device{
        margin-top: rem-calc(-70);

        @include breakpoint(small down){
            margin-top: 0;
            height: rem-calc(200);
            display: flex;
            flex-direction: column;
            justify-content:flex-end;
            align-items: center;
        }

        &--last{
            padding-right: rem-calc(20);

            @include breakpoint(small down){
                padding-right: rem-calc(10);
            }
        }
    }

    &__feature{

        .card{
            background: $white;
            box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.2);
            border: none;
            align-items: center;
            padding: rem-calc(110 60 40);
            position: relative;
            overflow: visible;
            margin-bottom: rem-calc(50);

            img{
                position: absolute;
                left: 50%;
                top: rem-calc(-24);
                transform: translate(-50%);
                height: rem-calc(121);
            }

            h3{
                font-family: 'Metronic Pro Bold';
                font-size: rem-calc(24);
                font-weight: bold;
                margin-bottom: rem-calc(25);
                text-align: center;
            }

            p{
                margin-bottom: rem-calc(25);
            }

            small{
                text-align: left;
                align-self: flex-start;
                font-size: rem-calc(14);
            }
        }

        .cell:last-child{

            .card{
                margin-bottom:0;
            }
        }
    }
}

.middle-banner{

    margin-top: rem-calc(-30);

    &__img{

        img{
            max-width: unset;
            width: 100%;
        }

        @include breakpoint(large down){
            height: rem-calc(600);

            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        @include breakpoint(medium down){
            height: rem-calc(400);
        }

        @include breakpoint(small down){
            height: rem-calc(270);
        }
    }
}
