.header{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;

    &__logo{
        margin-bottom: 0;

        @include breakpoint(small down){
            width: rem-calc(120);
        }
    }

    &__main{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: transparent;
        padding: rem-calc(30 0);
    }

    &__select{
        display: none;
        border-radius: rem-calc(20);
        background: $gray-dropdown;
        border: solid rem-calc(1) $white;
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a::after{
        content: none;
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a.pl::before{
        content:'';
        position: absolute;
        left: rem-calc(35);
        top:rem-calc(7);
        background: url('../img/pl/pl.png');
        background-size: contain;
        width: rem-calc(19);
        height: rem-calc(19);
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a{
        padding-left: rem-calc(64);
        padding-right: rem-calc(37);
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
        font-size: rem-calc(14);
        color: $black;
    }

    .dropdown.menu > li.opens-right > .is-dropdown-submenu{
        left: 50%;
        transform: translate(-50%);
        width: 80%;
        min-width: unset;
        background: $gray-dropdown;
        border: none;
        border-bottom-left-radius: rem-calc(20);
        border-bottom-right-radius: rem-calc(20);
        border: solid rem-calc(1) $white;
        border-top: none;
        overflow: hidden;

        li{
            background-color: $gray-dropdown;
            transition: all .3s ease-in-out;

            &:hover{
                background-color: darken($gray-dropdown, 10%);
            }
        }

        a{
            color: $black;
            font-size: rem-calc(14);
            text-align: center;
            position: relative;
            margin-left: rem-calc(30);

            &::before{
                content:'';
                position: absolute;
                left: rem-calc(-13);
                top:rem-calc(7);
                background: url('../img/pl/pl.png');
                background-size: contain;
                width: rem-calc(19);
                height: rem-calc(19);
            }
        }
    }
}
