.features{
    padding: rem-calc(65 0 70);
    background: $white;

    @include breakpoint(small down){
        padding-bottom: rem-calc(40);
    }

    &__lists{
        margin-bottom: rem-calc(80);

        @include breakpoint(small down){
            margin-bottom: rem-calc(40);
        }
    }

    h4{
        margin-bottom: rem-calc(10);
    }

    &__property{
        font-weight: bold;
    }

    p{
        margin-bottom: rem-calc(10);

        @include breakpoint(small down){
            font-size: rem-calc(16);
        }
    }
}
