.footer{

    padding: rem-calc(35 15);
    background: #2b2b2b;
    text-align: center;
    color: $white;
    font-size: rem-calc(16);

    &__list-horizontal{
        list-style: none;
        text-align: center;
        margin: 0;
        margin-bottom: rem-calc(25);

        li{
            display: inline-block;
            padding: rem-calc(0 7);

            a{
                padding: rem-calc(10);
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem-calc(49);
                height: rem-calc(49);
                transition: all .3s ease-in-out;

                i{
                    font-size: rem-calc(30);
                    color: $white;
                    transition: all .2s ease-in-out;
                }
            }

            &:first-child{

                a{
                    background-color: #3a5897;

                    &:hover{
                        background-color: $white;

                        i{
                            color: #3a5897;
                        }
                    }
                }
            }

            &:last-child{

                a{
                    background-color: #ce332c;

                    &:hover{
                        background-color: $white;

                        i{
                            color: #ce332c;
                        }
                    }
                }
            }
        }
    }
}
