.hero{

    @include breakpoint(large down) {
        height: rem-calc(700);

        .swiper-container{
            height: 100%;

            .hero__device-info{
                right: 0;
                transform: translate(0, -50%);
            }

            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    @include breakpoint(small down){
        height: rem-calc(600);
    }

    &__device-info{
        margin: rem-calc(15);
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translate(-50%,-50%);
        color: $white;

        @include breakpoint(small down){
            top: 41%;
        }

        &--mod{

            @include breakpoint(xlarge down){
                right: -15%;
            }
        }

        h2{
            opacity: 0;
            font-family: 'Metronic Pro Light';
            font-size: rem-calc(48);
            margin-bottom: rem-calc(20);
            transition: opacity 2s ease-in-out;
            line-height: 1.2;
        }

        p{
            opacity: 0;
            font-size: rem-calc(24);
            margin: 0;
            transition: opacity 2s ease-in-out;
        }
    }

    &__device-info-box{
        position: relative;
        padding: rem-calc(65 80);

        @include breakpoint(small down){
            padding: rem-calc(45 60);

            h2{
                font-size: rem-calc(36);
            }

            p{
                font-size: rem-calc(20);
            }
        }

        &::before,
        &::after{
            opacity: 0;
            transform: scale(0.2) skew(10deg,10deg);
            content:'';
            position: absolute;
            left: 0;
            top:0;
            background: url('../img/pl/left-corner.png');
            background-size: contain;
            width: rem-calc(78);
            height: rem-calc(79);
            transition: all 1.5s ease-in-out;
        }

        &::after{
            left: auto;
            top:auto;
            right: 0;
            bottom: 0;
            background: url('../img/pl/right-corner.png');
        }
    }

    .swiper-slide-active {

        .hero__device-info{

            h2,p,
            .hero__device-info-box::before,
            .hero__device-info-box::after{
                opacity: 1;
            }

            .hero__device-info-box::before,
            .hero__device-info-box::after{
                transform: scale(1) skew(0,0);
            }
        }
    }
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: rem-calc(40);
}

.swiper-container .swiper-slide>img{
    max-width: unset;
    width: 100%;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
    margin: rem-calc(0 6);
    width: rem-calc(14);
    height: rem-calc(14);
    background-color: $white;
    opacity:1;

    &-active{
        background-color: $orange;
    }
}
.sub-hero{
    background: $gray;
    text-align: center;
    padding: rem-calc(33 0 37);

    h2{
        font-family: 'Metronic Pro';
        font-size: rem-calc(36);

        span{
            display: block;
            font-size: rem-calc(24);
        }
    }

    a.button.hollow{
        font-family: 'Metronic Pro';
        font-size: rem-calc(14);
        border-radius: rem-calc(20);
        border: solid rem-calc(1) $orange;
        color: $orange;
        text-transform: uppercase;
        padding: rem-calc(8 20);
        margin-top: rem-calc(25);
        transition: all .3s ease-in-out;

        &:hover{
            background: $orange;
            color: $white;

        }
    }
}
