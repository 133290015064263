$orange: #f15922;
$gray: #f3f3f3;
$gray-dropdown: #f0edea;
$body-font: rem-calc(20);
.grid-container{
    max-width: rem-calc(1270);
    width: 100%;
    margin: auto;
}
body{
    background: $white;
    font-family: 'Metronic Pro Light';
}

h4{
    font-size: rem-calc(20);
    font-family: 'Metronic Pro Light';
}
